import api from "../api";

class MenusetService {
  getList(dataForm) {
    return api.get("/merchant/menuset", { params: dataForm });
  }

  store(dataForm) {
    return api.post("/merchant/menuset", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(dataForm) {
    return api.post("/merchant/menuset/update/" + dataForm.id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  menusetDetail(menuId) {
    return api.get("/merchant/menuset/" + menuId);
  }

  createCategory(dataForm) {
    return api.post("/merchant/menuset/create-category", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getListCategory(dataForm) {
    return api.post("/merchant/menuset/get-list-category", dataForm);
  }

  categoryDetail(categoryId) {
    return api.get("/merchant/menu-set/category/" + categoryId);
  }

  updateCategory(dataForm) {
    return api.post("/merchant/menu-set/category/update/" + dataForm.id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getListBranch(dataForm) {
    return api.post("/merchant/menu-set/get-list-branch", dataForm);
  }

  getPriceList(dataForm) {
    return api.post("/merchant/menu-set/get-price-list", dataForm);
  }
  
  updateCategoriesOrder(dataForm) {
    return api.post("/merchant/menu-set/update-categories-order", dataForm);
  }

  updateItemsOrder(dataForm) {
    return api.post("/merchant/menu-set/update-items-order", dataForm);
  }

  getBranchs(dataForm) {
    return api.post("/merchant/menu-set/get-branchs", dataForm);
  }

  deattachItem(id) {
    return api.post("/merchant/menu-set/category/deattach-item/" + id);
  }

  export(dataForm) {
    return api.post("/merchant/menu-set/export", dataForm);
  }

  import(dataForm) {
    return api.post("/merchant/menu-set/import", dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  deleteCategory(dataForm) {
    return api.post("/merchant/menuset/delete-category", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getPrinters(dataForm) {
    return api.post("/merchant/menu-set/get-printer-list", dataForm);
  }

  assignPrinterToCategory(dataForm) {
    return api.post("/merchant/menuset/assign-printer-to-category", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  assignPrinterToItem(dataForm) {
    return api.post("/merchant/menuset/assign-printer-to-item", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  assignPriceListOtherForItem(dataForm) {
    return api.post("/merchant/modifier-option-group/assign-price-list-other-for-item", dataForm);
  }
  
  addBundleItemsForMainItem(dataForm) {
    return api.post("/merchant/menuset/add-bundle-items-for-main-item", dataForm);
  }

  getBundleItemsForMainItem(dataForm) {
    return api.post("/merchant/menuset/get-bundle-items-for-main-item", dataForm);
  }

  deleteBundleItemsForMainItem(dataForm) {
    return api.post("/merchant/menuset/delete-bundle-items-for-main-item", dataForm);
  }

  getPriceListDeliveryPlatform(dataForm) {
    return api.post("/merchant/menu-set/get-price-list-delivery-platform", dataForm);
  }
}

export default new MenusetService();
