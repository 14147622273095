<template>
  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="row page-header min-vh-100">
          <div class="col-12">
            <div class="row vh-100 page-current">
              <div class="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center align-items-center background-minty hidden-md">
                <div class="card card-plain">
                  <div class="card-body name-minty align-self-center">
                    MINTY
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                <div class="card card-plain">
                  <div class="card-body">
                    <div class="welcome-minty d-flex justify-content-center pb-6">Welcome to MINTY CRM Management Portal!
                    </div>

                    <div v-if="showingVerifyForm == true">
                      <!-- <div v-if="isErrorWhenVerifyCodeMerchant == true" style="background-color: #FC4853;padding: 10px;margin-bottom: 30px;border-radius: 5px;">
                        <div style="color: white">The verification code incorrect.</div>
                      </div> -->
                      <div style="background-color: #E1F3F8;padding: 10px;margin-bottom: 30px;border-radius: 5px;">
                        <div style="color: #83A7B0">The code factor verification code has been sent.</div>
                      </div>
                      <h3>Two Factor Verification</h3>
                      <div style="font-size: 13.5px;display: flex;">You have received an email which contains verify code. If you haven't received it, press <div @click="resendEmailVerify()" style="color: #98D5EC;margin-left: 3px;cursor: pointer;">here</div></div>

                      <form @submit.prevent="verifyMerchant">
                        <div class="mb-3 mt-3">
                          <div class="form-control p-0" id="form-control">
                            <material-input v-model="verify_code" extraClass="p-2" type="text" size="lg" placeholder="Enter verify code" />
                          </div>
                        </div>
                        <div class="text-center" style="display: flex;align-items: center;justify-content: center;gap: 25px;">
                          <material-button @click="backHome()" type="button" class="mt-4 px-6 z-index" variant="gradient" color="success">Back
                          </material-button>
                          <material-button type="submit" class="mt-4 px-6 z-index" variant="gradient" color="success">Verify
                          </material-button>
                        </div>
                      </form>
                    </div>

                    <form @submit.prevent="checkCredentials" v-else>
                      <div class="mb-3">
                        <label for="email" class="ms-0 lable-input">Email</label>
                        <div class="form-control p-0" id="email">
                          <material-input v-model="form.email" extraClass="p-2" type="text" name="email" size="lg" />
                        </div>
                      </div>
                      <div class="mb-3">
                        <label for="password" class="ms-0 lable-input">Password</label>
                        <div class="form-control p-0" id="password">
                          <material-input v-model="form.password" extraClass="p-2" type="password" name="password" size="lg" />
                        </div>
                      </div>
                      <div class="d-flex justify-content-end forgot-pass" @click="resetPassword()">
                        <a>Forgot Password</a>
                      </div>
                      <div class="text-center">
                        <material-button class="mt-4 px-6 z-index" variant="gradient" color="success">
                          Login
                        </material-button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 wrap-copyright">
            <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12">&nbsp;</div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div class="text-center p-4 lable-input justify-content-center">
                      © MINTY @ {{ this.currentYear }}
                    </div>
                  </div>
              </div>
          </div>
          <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger'" title="" date="" :description="message"
              :icon="{ component: 'campaign', color: 'white' }" color="danger" :close-handler="closeSnackbar" />
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import router from '@/router'
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import moment from "moment";

export default {
  name: "sign-in",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialSnackbar,
    MaterialLoading
  },
  data() {
    return {
      message: '',
      form: {
        email: '',
        password: '',
      },
      snackbar: null,
      loaderActive: false,
      currentYear: 2023,
      isSendingOtp: false,
      showingVerifyForm: false,
      verify_code: '',
      merchantAdminId: 0,
      isErrorWhenVerifyCodeMerchant: false
    };
  },
  mounted() {
    const code = localStorage.getItem('MERCHANT_CODE', '');
    const token = TokenService.getLocalAccessToken();
    if (token && code) {
      router.push('home')
    }
    this.currentYear = moment().year();
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    login: function () {
      var _this = this;
      _this.closeSnackbar()
      _this.showLoader()
      _this.$store.dispatch("auth/login", this.form).then(
        (data) => {
          if (data != undefined && !data.result.isSuccess) {
            _this.message = data.result.message
            _this.hideLoader()
            _this.showSnackbarError();
          } else {
            localStorage.setItem('MERCHANT_ID', data.data.merchantId[0])
            localStorage.setItem('MERCHANT_CODE', data.data.merchantCode[0]);
            localStorage.setItem('MERCHANT_NAME', data.data.merchantName[0]);
            localStorage.setItem('MERCHANT_ADMIN_NAME', data.data.user.first_name+ " " +data.data.user.last_name)
            // localStorage.setItem('MERCHANT_STRIPE_ACCESS_TOKEN', (data.data.user.stripe_access_token === null ? "" :data.data.user.stripe_access_token))
            // localStorage.setItem('MERCHANT_STRIPE_REFRESH_TOKEN', (data.data.user.stripe_refresh_token === null ? "" :data.data.user.stripe_refresh_token))
            // localStorage.setItem('MERCHANT_STRIPE_USER_ID', (data.data.user.stripe_user_id === null ? "" :data.data.user.stripe_user_id))
            _this.hideLoader()
            localStorage.removeItem('logout-event');
            router.push('home')
          }
        },
        (error) => {
          _this.message = error.response.data.result.message
          _this.hideLoader()
          _this.showSnackbarError();
        }
      );
    },
    resetPassword() {
      this.$router.push('/reset-password');
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    showSnackbarError() {
      this.snackbar = 'danger'
      setTimeout(() => { this.snackbar = null; }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    checkCredentials: function () {
      if(this.isSendingOtp == false) {
        var _this = this;
        _this.isSendingOtp = true;
        _this.closeSnackbar()
        _this.showLoader()
        _this.$store.dispatch("auth/checkCredentials", this.form).then(
          (data) => {
            if (data != undefined && !data.result.isSuccess) {
              _this.message = data.result.message
              _this.hideLoader()
              _this.showSnackbarError();
              _this.isSendingOtp = false;
            } else {
              console.log("data", data);
              if(data && data.data && data.data.user && data.data.user.is_enable_2fa_login == 1) {
                _this.hideLoader()
                this.sendEmailVerify(data.data.user);
              } else {
                _this.isSendingOtp = false;
                this.login();
              }
            }
          },
          (error) => {
            _this.message = error.response.data.result.message
            _this.hideLoader()
            _this.showSnackbarError();
          }
        );
      }
    },
    sendEmailVerify(data) {
      var _this = this;
      _this.closeSnackbar()
      _this.showLoader()
      var dataForm = {
        id: data.id,

      }
      AuthService.sendEmailVerify(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            // console.log("response.data", response.data);
            this.showingVerifyForm = true;
            this.merchantAdminId = data.id;
            _this.hideLoader()
          } else {
            _this.message = response.data.result.message;
            _this.hideLoader()
            _this.showSnackbarError();
          }
          _this.isSendingOtp = false;
        },
        (error) => {
          _this.isSendingOtp = false;
          _this.message = error.response.data.result.message
          _this.hideLoader()
          _this.showSnackbarError();
        }
      );
    },
    verifyMerchant() {
      var _this = this;
      _this.closeSnackbar()
      _this.showLoader()
      var dataForm = {
        id: this.merchantAdminId,
        otp: this.verify_code
      }
      _this.isSendingOtp = true;
      AuthService.verifyMerchant(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.login();
          } else {
            this.isErrorWhenVerifyCodeMerchant = true;
            _this.message = response.data.result.message;
            _this.hideLoader()
            _this.showSnackbarError();
          }
        },
        (error) => {
          this.isErrorWhenVerifyCodeMerchant = true;
          _this.message = error.response.data.result.message
          _this.hideLoader()
          _this.showSnackbarError();
        }
      );
    },
    backHome() {
      location.reload();
    },
    resendEmailVerify() {
      if(this.isSendingOtp == false) {
        var _this = this;
        _this.closeSnackbar()
        _this.showLoader()
        _this.isSendingOtp = true;
        var dataForm = {
          id: this.merchantAdminId,
        }
        AuthService.resendEmailVerify(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              // console.log("response.data", response.data);
              this.showingVerifyForm = true;
              _this.hideLoader()
            } else {
              _this.message = response.data.result.message;
              _this.hideLoader()
              _this.showSnackbarError();
            }
            _this.isSendingOtp = false;
          },
          (error) => {
            _this.isSendingOtp = false;
            _this.message = error.response.data.result.message
            _this.hideLoader()
            _this.showSnackbarError();
          }
        );
      }
    },
  },
};
</script>
<style>
.name-minty {
  font-size: 60px;
  font-weight: 600;
  color: #fff !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.background-minty {
  background-color: #133067 ;
}

.welcome-minty {
  font-size: 25px;
  font-weight: 600;
  color: #133067 ;
  font-family: "Roboto Slab", sans-serif !important;
}

.lable-input {
  font-size: 18px;
  font-weight: 500;
  color: #133067 ;
  font-family: "Roboto Slab", sans-serif;
}

.forgot-pass {
  color: #007BFF;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
.wrap-copyright {
  position: absolute;
  bottom: 0px;
}
@media (max-width: 500px) {
  .card-body {
    padding-top: 0px !important;
  }
  .card-body > .welcome-minty {
    padding-bottom: 12px !important;
  }
}
</style>
