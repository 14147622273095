<template>
    <div>
        <material-button
            class="d-none"
            data-bs-toggle="modal"
            data-bs-target="#modalAddSetMenuItem"
            id="show-modal-add-set-menu-item"
        ></material-button>

        <div class="modal fade" id="modalAddSetMenuItem" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Bundle Items for {{ itemName }}</h5>
                        <i
                            style="cursor: pointer"
                            class="material-icons btn-close-modal justify-content-end"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >clear</i>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card-custom">
                                    <div class="px-0 pb-2">
                                        <div class="table-responsive p-0 custom-table">
                                            <table class="table align-items-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th class="font-weight-bolder" style="width: 5%;"></th>
                                                        <th class="font-weight-bolder" style="width: 10%;"></th>
                                                        <th class="font-weight-bolder" style="width: 15%;">{{ $t("Image") }}</th>
                                                        <th class="font-weight-bolder" style="width: 10%;">{{ $t("Item No") }}</th>
                                                        <th class="font-weight-bolder" style="width: 50%;">{{ $t("Name") }}</th>
                                                        <th class="font-weight-bolder" style="width: 10%;">{{ $t("Quantity") }}</th>
                                                    </tr>
                                                </thead>
                                                <draggable
                                                    v-model="data"
                                                    group="itemByGroupOrSubGroupData"
                                                    item-key="id"
                                                    tag="tbody"
                                                    handle=".handleMoveItem"
                                                >
                                                    <template #item="{ index, element }">
                                                        <tr
                                                            :class="{
                                                            hovered: hoveredRow === index,
                                                            }"
                                                            @mouseenter="hoveredRow = index"
                                                            @mouseleave="hoveredRow = null"
                                                            style="cursor: pointer"
                                                        >
                                                            <td class="text-left text-xs" style="width: 5%;">
                                                                <i
                                                                    style="cursor: pointer;width: 25px;"
                                                                    class="material-icons-round opacity-10 handleMoveItem"
                                                                    >drag_indicator_icon</i
                                                                >
                                                            </td>
                                                            <td class="text-left text-xs" style="width: 10%;">
                                                                <i
                                                                    style="cursor: pointer"
                                                                    class="material-icons me-1"
                                                                    @click="deleteItems(index, element.id)"
                                                                >delete</i>
                                                                <i
                                                                    style="cursor: pointer"
                                                                    class="material-icons me-1"
                                                                    @click="addBundledItem()"
                                                                >add</i>
                                                            </td>
                                                            <td class="text-left text-xss" style="width: 15%;">
                                                                <img
                                                                    class="image-url"
                                                                    v-if="!element.isNew"
                                                                    :src="element.itemImage"
                                                                />
                                                            </td>
                                                            <td class="text-left text-xss cursor-pointer font-weight-bolder cl-22" style="width: 10%;">
                                                                <input
                                                                    v-if="element.isNew"
                                                                    v-model="element.itemNo"
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="Enter Item No"
                                                                    style="text-align: left;"
                                                                />
                                                                <span v-else>{{ element.itemNo }}</span>
                                                            </td>
                                                            <td class="text-left text-xss" style="width: 50%;">
                                                                <span v-if="!element.isNew">{{ element.itemName }}</span>
                                                            </td>
                                                            <td class="text-left text-xss" style="width: 10%;">
                                                                <!-- <input
                                                                    v-if="element.isNew"
                                                                    v-model="element.quantity"
                                                                    type="number"
                                                                    class="form-control"
                                                                    placeholder="Enter Quantity"
                                                                    style="text-align: left;"
                                                                    @input="validateQuantity(element)"
                                                                />
                                                                <span v-else>{{ element.quantity }}</span> -->
                                                                <input
                                                                    v-model="element.quantity"
                                                                    type="number"
                                                                    class="form-control"
                                                                    placeholder="Enter Quantity"
                                                                    style="text-align: left;border-bottom: 1px solid darkgray;border-radius: 0px;"
                                                                    @input="validateQuantity(element)"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </draggable>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex" style="margin-top: 20px;">
                            <material-button class="mx-2 btn btn-sm btn-background" @click="saveItems">
                                Save
                            </material-button>
                            <material-button
                                class="mx-2 btn btn-sm btn-background"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                Cancel
                            </material-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <material-loading :active="loaderActive" />
    </div>
</template>

<script>

import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../services/product/menuset.service";
import draggable from "vuedraggable";

export default {
    name: "create-category",
    components: {
        MaterialButton,
        MaterialLoading,
        draggable,
    },
    props: {
        keyReload: {
            type: String,
            required: true,
        },
        categoryId: {
            type: Number,
            required: true,
        },
        itemId: {
            type: Number,
            required: true,
        },
        itemName: {
            type: String,
            required: false,
        },
        setId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            data: [
                // { id: 0, itemNo: "D05", itemName: "烤海盐鲜虾串", quantity: 2 }
            ],
            newItem: {
                itemNo: "",
                itemName: "",
                itemImage: "",
                quantity: 1,
            },
            loaderActive: false,
        };
    },
    methods: {
        addDefaultItem() {
            this.data.push({
                id: 0,
                itemNo: '',
                itemName: '',
                itemImage: '',
                quantity: 1,
                isNew: true,
            });
        },
        setTimeOutNotification() {
            setTimeout(() => {
                this.snackbar = null;
            }, 3000);
        },
        addItem(index) {
            const newItem = { ...this.data[index], quantity: 1 };
            this.data.push(newItem);
        },
        removeItem(index) {
            this.data.splice(index, 1);
        },
        addNewItem() {
            if (this.newItem.itemNo && this.newItem.itemName) {
                this.data.push({ ...this.newItem });
                this.newItem = { itemNo: "", itemName: "", quantity: 1 };
            }
        },
        saveItems() {
            this.showLoader();
            var dataForm = {
                main_item_id: this.itemId,
                items: this.data,
                menu_set_id: this.setId,
                category_id: this.categoryId
            };
            MenusetService.addBundleItemsForMainItem(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.snackbar = "success";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                        $(".btn-close-modal").click();
                        this.$emit('reload-list-category', 'Save Successfully');
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        deleteItems(index, id) {
            if(id == 0) {
                this.data.splice(index, 1);
            } else {
                this.showLoader();
                var dataForm = {
                    id: id
                };
                MenusetService.deleteBundleItemsForMainItem(dataForm).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.snackbar = "success";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                            this.data.splice(index, 1);
                            if(this.data && this.data.length == 0) {
                                this.addDefaultItem();
                            }
                            // $(".btn-close-modal").click();
                            // this.$emit('reload-list-category', 'Deleted Successfully');
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            }
        },
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        addBundledItem() {
            const newItem = {
                itemNo: '',
                itemName: '',
                itemImage: '',
                quantity: 1,
                isNew: true,
            };
            this.data.push(newItem);
        },
        async getData() {
            this.data = [];
            this.showLoader();
            var dataForm = {
                main_item_id: this.itemId,
                menu_set_id: this.setId,
                category_id: this.categoryId
            };

            await MenusetService.getBundleItemsForMainItem(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        if(response.data.data && response.data.data.length > 0) {
                            this.data = response.data.data;
                        } else {
                            this.addDefaultItem();
                        }
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        validateQuantity(element) {
            if (element.quantity <= 0 || isNaN(element.quantity)) {
                element.quantity = '';
            }
        },
    },
    watch: {
        async keyReload() {
            await this.getData();
        }
    },
};
</script>

<style scoped>
.modal-body {
    max-height: 85vh;
    overflow-y: auto;
    padding-bottom: 25px;
}

.table {
    width: 100%;
}

.table th,
.table td {
    text-align: left;
}

.form-control-sm {
    font-size: 0.875rem;
}

.btn-background {
    background-color: #007bff;
    color: #fff;
}
.modal-dialog {
  max-width: 700px;
}
.hovered {
  background-color: #f5f5f5 !important;
}
.image-url {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
</style>
