<template>
  <div v-if="isLoadData && smsBal == 0 && isShowWarningSms == 2" style="display: flex;width: 100%;background: #ef5350;align-items: center;">
    <div style="color: white;padding: 10px;width: 95%;">
      You have {{ smsBal }} SMS Credit. To ensure smooth communication with your member, purchase more 
      <a id="nav_item_2" class="" href="/sms-setup" style="color: white; text-decoration: underline;">here!</a>
    </div>
    <div style="width: 5%;cursor: pointer;" @click="closeWarningSms()">
      <div>
        <i class="material-icons opacity-10 pe-1" data-v-fae5bece="" style="font-size: 18px;color: white;margin-top:8px;">close</i>
      </div>
    </div>
  </div>
  <div class="page-header align-items-start min-vh-100 my-transacions">
    <span class="mask bg-white"></span>
    <div class="container mb-5">
      <div class="row">
        <div class="col-lg-12 col-12 mx-auto">
          <div class="card-body" style="padding: 0rem 0rem">
            <div>
              <div class="pb-2 px-2 pt-3">
                <div class="title-page">
                  <div>{{ merchant_name }}</div>
                  <div>HOME - Dashboards</div>
                </div>
                <div v-if="isLoadData">
                  <div class="data-over-time row mt-3">
                    <div class="col-lg-3 col-12 d-flex align-items-center">
                      <material-button
                        @click="showTopupDashboard"
                        class="btn-background btn-sm"
                        style="text-transform: none"
                      >
                        <i
                          class="material-icons opacity-10 pe-1"
                          style="font-size: 18px"
                          >add</i
                        >Add
                      </material-button>
                    </div>
                    <div class="col-lg-9 col-12">
                      <div class="d-flex justify-content-end align-items-center">
                        <div class="me-3">START OVERVIEW FOR</div>
                        <Multiselect
                          v-model="filter_time"
                          :options="times"
                          :canClear="false"
                          class="mw-250px m-0"
                        />
                      </div>
                    </div>
                    <div
                      class="list-data-box row mt-5"
                      v-if="
                        dataDashBoard.total_new_members ||
                        dataDashBoard.total_topup ||
                        dataDashBoard.total_txn ||
                        dataDashBoard.total_member_visit || 
                        dataDashBoard.total_sales ||
                        dataDashBoard.bill_count ||
                        dataDashBoard.void_count
                      "
                    >
                      <div
                        class="col-lg-3 col-sm-6 col-12 data-box"
                        v-if="dataDashBoard.total_new_members"
                      >
                        <div class="icon-box">
                          <i class="material-icons opacity-10">person</i>
                        </div>
                        <div class="b-content">
                          <div class="b-title">New members</div>
                          <div class="b-data">
                            {{ dataDashBoard.total_new_members.current ?? "-" }}
                          </div>
                          <div
                            class="d-flex align-items-center justify-content-end"
                            :class="
                              'b-mom-' +
                              dataDashBoard.total_new_members.mom.status
                            "
                          >
                            <div
                              class="pe-1"
                              v-if="
                                dataDashBoard.total_new_members.mom.status !== 0
                              "
                            >
                              <i
                                class="material-icons opacity-10"
                                style="width: fit-content"
                              >
                                {{
                                  dataDashBoard.total_new_members.mom.status == 1
                                    ? "north_icon"
                                    : "south_icon"
                                }}
                              </i>
                            </div>
                            <div>
                              {{
                                dataDashBoard.total_new_members.mom.ratio ?? "NA"
                              }}
                              MoM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-3 col-sm-6 col-12 data-box"
                        v-if="dataDashBoard.total_topup"
                      >
                        <div class="icon-box">
                          <i class="material-icons opacity-10">attach_money</i>
                        </div>
                        <div class="b-content">
                          <div class="b-title">Total topup</div>
                          <div class="b-data">
                            {{ dataDashBoard.total_topup.current ?? "-" }}
                          </div>
                          <div
                            class="d-flex align-items-center justify-content-end"
                            :class="
                              'b-mom-' + dataDashBoard.total_topup.mom.status
                            "
                          >
                            <div
                              class="pe-1"
                              v-if="dataDashBoard.total_topup.mom.status !== 0"
                            >
                              <i
                                class="material-icons opacity-10"
                                style="width: fit-content"
                              >
                                {{
                                  dataDashBoard.total_topup.mom.status == 1
                                    ? "north_icon"
                                    : "south_icon"
                                }}
                              </i>
                            </div>
                            <div>
                              {{ dataDashBoard.total_topup.mom.ratio ?? "NA" }}
                              MoM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-3 col-sm-6 col-12 data-box"
                        v-if="dataDashBoard.total_txn"
                      >
                        <div class="icon-box">
                          <i class="material-icons opacity-10">attach_money</i>
                        </div>
                        <div class="b-content">
                          <div class="b-title">Total member Txn</div>
                          <div class="b-data">
                            {{ dataDashBoard.total_txn.current ?? "-" }}
                          </div>
                          <div
                            class="d-flex align-items-center justify-content-end"
                            :class="'b-mom-' + dataDashBoard.total_txn.mom.status"
                          >
                            <div
                              class="pe-1"
                              v-if="dataDashBoard.total_txn.mom.status !== 0"
                            >
                              <i
                                class="material-icons opacity-10"
                                style="width: fit-content"
                              >
                                {{
                                  dataDashBoard.total_txn.mom.status == 1
                                    ? "north_icon"
                                    : "south_icon"
                                }}
                              </i>
                            </div>
                            <div>
                              {{ dataDashBoard.total_txn.mom.ratio ?? "NA" }} MoM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-3 col-sm-6 col-12 data-box"
                        v-if="dataDashBoard.total_member_visit"
                      >
                        <div class="icon-box">
                          <i class="material-icons opacity-10">tour</i>
                        </div>
                        <div class="b-content">
                          <div class="b-title">Total member visit</div>
                          <div class="b-data">
                            {{ dataDashBoard.total_member_visit.current ?? "-" }}
                          </div>
                          <div
                            class="d-flex align-items-center justify-content-end"
                            :class="
                              'b-mom-' +
                              dataDashBoard.total_member_visit.mom.status
                            "
                          >
                            <div
                              class="pe-1"
                              v-if="
                                dataDashBoard.total_member_visit.mom.status !== 0
                              "
                            >
                              <i
                                class="material-icons opacity-10"
                                style="width: fit-content"
                              >
                                {{
                                  dataDashBoard.total_member_visit.mom.status == 1
                                    ? "north_icon"
                                    : "south_icon"
                                }}
                              </i>
                            </div>
                            <div>
                              {{
                                dataDashBoard.total_member_visit.mom.ratio ?? "NA"
                              }}
                              MoM
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-3 col-sm-6 col-12 data-box"
                        v-if="dataDashBoard.hasOwnProperty('total_sales')"
                      >
                        <div class="icon-box">
                          <i class="material-icons opacity-10">attach_money</i>
                        </div>
                        <div class="b-content">
                          <div class="b-title">Total sales</div>
                          <div class="b-data">
                            {{ dataDashBoard.total_sales ?? "-" }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-3 col-sm-6 col-12 data-box"
                        v-if="dataDashBoard.hasOwnProperty('bill_count')"
                      >
                        <div class="icon-box">
                          <i class="material-icons opacity-10">tour</i>
                        </div>
                        <div class="b-content">
                          <div class="b-title">Bill Count</div>
                          <div class="b-data">
                            {{ dataDashBoard.bill_count ?? "-" }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-3 col-sm-6 col-12 data-box"
                        v-if="dataDashBoard.hasOwnProperty('void_count')"
                      >
                        <div class="icon-box">
                          <i class="material-icons opacity-10">tour</i>
                        </div>
                        <div class="b-content">
                          <div class="b-title">Void Count</div>
                          <div class="b-data">
                            {{ dataDashBoard.void_count ?? "-" }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="list-chart row mt-5"
                    >
                      <div
                        class="col-lg-6 col-12 mt-3"
                        v-if="dataDashBoard.highest_revenue_items"
                      >
                        <div class="chart">
                          <div class="highest-revenue-items-chart">
                            <div class="text-start d-title">Items Revenue Leaderboard</div>
                            <bar-chart-horizontal
                              v-if="showHighestRevenueItemsChart"
                              :series="dataDashBoard.highest_revenue_items.xData"
                              :labels="dataDashBoard.highest_revenue_items.yData"
                            ></bar-chart-horizontal>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-12 mt-3"
                        v-if="dataDashBoard.highest_revenue_items_by_type"
                      >
                        <div class="chart">
                          <div class="highest-revenue-items-by-type-chart">
                            <div class="text-start d-title">Items Revenue Leaderboard By Type</div>
                            <bar-chart-horizontal
                              v-if="showHighestRevenueItemsChartByType"
                              :series="dataDashBoard.highest_revenue_items_by_type.xData"
                              :labels="dataDashBoard.highest_revenue_items_by_type.yData"
                              :crossFilter="true"
                            ></bar-chart-horizontal>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-6 col-12 mt-3"
                        v-if="dataDashBoard.total_revenue_branches"
                      >
                        <div class="chart">
                          <div class="total-revenue-branches-chart">
                            <div class="text-start d-title">Branches Revenue Leaderboard</div>
                            <bar-chart-vertical
                              v-if="showTotalRevenueBranchesChart"
                              :series="dataDashBoard.total_revenue_branches.yData"
                              :labels="dataDashBoard.total_revenue_branches.xData"
                              :crossFilter="true"
                            ></bar-chart-vertical>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      class="list-chart mt-5"
                      v-if="dataDashBoard.outlet_sales || dataDashBoard.outlet_sales_by_time"
                    >
                      <div class="chart">
                        <div class="daily-new-member-chart">
                          <div class="text-start d-title">Outlet Sales (S$)</div>
                          <div></div>
                          <line-chart
                            :series="dataDashBoard.outlet_sales.yData"
                            :labels="dataDashBoard.outlet_sales.xData"
                          ></line-chart>
                          <div class="text-start d-title mt-3 mb-3"></div>
                          <group-line-chart
                            :series="dataDashBoard.outlet_sales_by_time.yData"
                            :labels="dataDashBoard.outlet_sales_by_time.xData"
                          ></group-line-chart>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div
                      class="list-chart mt-5"
                      v-if="dataDashBoard.outlet_sales_by_gender"
                    >
                      <div class="chart">
                        <div class="daily-new-member-chart">
                          <div class="text-start d-title">
                            Outlet Revenue by Gender
                          </div>
                          <group-bar-chart
                            :series="dataDashBoard.outlet_sales_by_gender.yData"
                            :labels="dataDashBoard.outlet_sales_by_gender.xData"
                          ></group-bar-chart>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div
                      class="list-chart mt-5"
                      v-if="dataDashBoard.outlet_sales_by_age"
                    >
                      <div class="chart">
                        <div class="daily-new-member-chart">
                          <div class="text-start d-title">
                            Outlet Revenue by Age Group
                          </div>
                          <group-bar-chart
                            :series="dataDashBoard.outlet_sales_by_age.yData"
                            :labels="dataDashBoard.outlet_sales_by_age.xData"
                          ></group-bar-chart>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div
                      class="list-chart mt-5"
                      v-if="dataDashBoard.outlet_sales_by_income"
                    >
                      <div class="chart">
                        <div class="daily-new-member-chart">
                          <div class="text-start d-title">
                            Outlet Revenue by Income Group
                          </div>
                          <group-bar-chart
                            :series="dataDashBoard.outlet_sales_by_income.yData"
                            :labels="dataDashBoard.outlet_sales_by_income.xData"
                          ></group-bar-chart>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="w-100 line"></div>
                  <div
                    class="list-data-box row mt-5 not-over-time"
                    v-if="
                      dataDashBoardAllTime.total_members ||
                      dataDashBoardAllTime.total_wallet_balance ||
                      dataDashBoardAllTime.total_topup_bonus ||
                      dataDashBoardAllTime.total_discount_code_redeemed
                    "
                  >
                    <div
                      class="col-xxl-3 col-md-6 col-12 data-box"
                      v-if="dataDashBoardAllTime.hasOwnProperty('total_members')"
                    >
                      <div class="icon-box">
                        <i class="material-icons opacity-10">person</i>
                      </div>
                      <div class="b-content h-100">
                        <div class="b-title">Total member</div>
                        <div class="b-data d-flex justify-content-end">
                          {{ dataDashBoardAllTime.total_members.all ?? "-" }}
                          <div class="separator-line"></div>
                          Active: {{ dataDashBoardAllTime.total_members.active ?? "-" }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-3 col-md-6 col-12 data-box"
                      v-if="dataDashBoardAllTime.hasOwnProperty('total_wallet_balance')"
                    >
                      <div class="icon-box">
                        <i class="material-icons opacity-10">attach_money</i>
                      </div>
                      <div class="b-content h-100">
                        <div class="b-title">Total wallet balance</div>
                        <div class="b-data">
                          {{ dataDashBoardAllTime.total_wallet_balance ?? "-" }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-3 col-md-6 col-12 data-box"
                      v-if="dataDashBoardAllTime.hasOwnProperty('total_topup_every_time')"
                    >
                      <div class="icon-box">
                        <i class="material-icons opacity-10">attach_money</i>
                      </div>
                      <div class="b-content h-100">
                        <div class="b-title">Total topup</div>
                        <div class="b-data">
                          {{ dataDashBoardAllTime.total_topup_every_time ?? "-" }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-3 col-md-6 col-12 data-box"
                      v-if="dataDashBoardAllTime.hasOwnProperty('total_topup_bonus')"
                    >
                      <div class="icon-box">
                        <i class="material-icons opacity-10">attach_money</i>
                      </div>
                      <div class="b-content h-100">
                        <div class="b-title">Total bonus</div>
                        <div class="b-data">
                          {{ dataDashBoardAllTime.total_topup_bonus ?? "-" }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-xxl-3 col-md-6 col-12 data-box"
                      v-if="dataDashBoardAllTime.hasOwnProperty('total_cashback')"
                    >
                      <div class="icon-box">
                        <i class="material-icons opacity-10">attach_money</i>
                      </div>
                      <div class="b-content h-100">
                        <div class="b-title">Total cashback</div>
                        <div class="b-data">
                          {{ dataDashBoardAllTime.total_cashback ?? "-" }}
                        </div>
                      </div>
                    </div>
                    <div 
                      class="col-xxl-3 col-md-6 col-12 data-box"
                      v-if="dataDashBoardAllTime.hasOwnProperty('total_discount_code_redeemed')"
                    >
                      <div class="icon-box">
                        <i class="material-icons opacity-10">redeem</i>
                      </div>
                      <div
                        class="b-content h-100"
                        v-if="
                          typeof dataDashBoardAllTime.total_discount_code_redeemed !==
                          'undefined'
                        "
                      >
                        <div class="b-title">Total discount code redeemed</div>
                        <div class="b-data">
                          {{ dataDashBoardAllTime.total_discount_code_redeemed ?? "-" }}
                        </div>
                      </div>
                    </div>
                    <div 
                      class="col-xxl-3 col-md-6 col-12 data-box"
                      v-if="dataDashBoardAllTime.hasOwnProperty('total_donation')"
                    >
                      <div class="icon-box">
                        <i class="material-icons opacity-10">attach_money</i>
                      </div>
                      <div
                        class="b-content h-100"
                        v-if="
                          typeof dataDashBoardAllTime.total_donation !==
                          'undefined'
                        "
                      >
                        <div class="b-title">Total donation</div>
                        <div class="b-data">
                          {{ dataDashBoardAllTime.total_donation ?? "-" }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-ranking row mt-5"
                    v-if="
                      dataDashBoardAllTime.top_members_spend ||
                      dataDashBoardAllTime.top_members_topup
                    "
                  >
                    <div
                      class="col-lg-6 col-12"
                      v-if="dataDashBoardAllTime.top_members_spend"
                    >
                      <div class="t-ranking h-100">
                        <div class="text-start d-title">Spending Leaderboard</div>
                        <div class="table-responsive p-0 mt-3">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th>Row No</th>
                                <th>Member Name</th>
                                <th>Phone No</th>
                                <th>Amount Spend</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  item, index
                                ) in dataDashBoardAllTime.top_members_spend"
                                :key="index"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.phone_number }}</td>
                                <td>
                                  S$
                                  {{
                                    new Intl.NumberFormat("en", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }).format(parseFloat(item.total_spend ?? 0))
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-6 col-12"
                      v-if="dataDashBoardAllTime.top_members_topup"
                    >
                      <div class="t-ranking h-100">
                        <div class="text-start d-title">Top-up Leaderboard</div>
                        <div class="table-responsive p-0 mt-3">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th>Row No</th>
                                <th>Member Name</th>
                                <th>Phone No</th>
                                <th>Amount Top Up</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  item, index
                                ) in dataDashBoardAllTime.top_members_topup"
                                :key="index"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.phone_number }}</td>
                                <td>
                                  S$
                                  {{
                                    new Intl.NumberFormat("en", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }).format(parseFloat(item.total_topup ?? 0))
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-chart row mt-5"
                    v-if="
                      dataDashBoardAllTime.total_member_tiers ||
                      dataDashBoardAllTime.total_revenue_tiers ||
                      dataDashBoardAllTime.total_member_gender
                    "
                  >
                    <div
                      class="col-lg-4 col-12"
                      v-if="dataDashBoardAllTime.total_member_gender"
                    >
                      <div class="chart">
                        <div class="daily-new-member-chart">
                          <div class="text-start d-title">
                            Gender Distribution
                          </div>
                          <pie-chart
                            :series="dataDashBoardAllTime.total_member_gender.yData"
                            :labels="dataDashBoardAllTime.total_member_gender.xData"
                          ></pie-chart>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 col-12"
                      v-if="dataDashBoardAllTime.total_member_tiers"
                    >
                      <div class="chart">
                        <div class="daily-new-member-chart">
                          <div class="text-start d-title">
                            Member Tier Distribution
                          </div>
                          <pie-chart
                            :series="dataDashBoardAllTime.total_member_tiers.yData"
                            :labels="dataDashBoardAllTime.total_member_tiers.xData"
                          ></pie-chart>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 col-12"
                      v-if="dataDashBoardAllTime.total_revenue_tiers"
                    >
                      <div class="chart">
                        <div class="daily-new-member-chart">
                          <div class="text-start d-title">Revenue By Tier</div>
                          <pie-chart
                            :series="dataDashBoardAllTime.total_revenue_tiers.yData"
                            :labels="dataDashBoardAllTime.total_revenue_tiers.xData"
                          ></pie-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalDashboardComponet
      @reload-data="reloadData()"
    ></ModalDashboardComponet>
  </div>
  <material-loading :active="loaderActive" />
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
</template>

<script>
import router from "@/router";
import TokenService from "../services/token.service";
import Multiselect from "@vueform/multiselect";
import DashboardService from "../services/dashboard.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import BarChartHorizontal from "./components/chart/BarChartHorizontal.vue";
import BarChartVertical from "./components/chart/BarChartVertical.vue";
// import GroupBarChart from "../views/components/chart/GroupBarChart.vue";
// import GroupLineChart from "../views/components/chart/GroupLineChart.vue";
import PieChart from "../views/components/chart/PieChart.vue";
// import LineChart from "../views/components/chart/LineChart.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import ModalDashboardComponet from "@/components/ModalDashboardComponet.vue";
import $ from "jquery";
import SmsSetupService from "../services/smsSetup.service";
import EventBus from "vue3-eventbus";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

export default {
  name: "home",
  components: {
    Multiselect,
    MaterialLoading,
    BarChartHorizontal,
    BarChartVertical,
    // GroupBarChart,
    // GroupLineChart,
    PieChart,
    // LineChart,
    MaterialButton,
    ModalDashboardComponet,
    MaterialSnackbar
  },
  data() {
    return {
      snackbar: null,
      message: "",
      merchant_name: "",
      isLoadData: false,
      loaderActive: false,
      filter_time: 1,
      times: [
        { value: 1, label: "Today" },
        { value: 2, label: "Yesterday" },
        { value: 3, label: "Last 7 days" },
        { value: 4, label: "Last 30 days" },
        { value: 5, label: "This month" },
        { value: 7, label: "This year" },
        { value: 10, label: "All time" },
      ],
      dataDashBoard: [],
      dataDashBoardAllTime: [],
      smsBal: 0,
      isShowWarningSms: 1, // never show,
      showHighestRevenueItemsChart: false,
      showHighestRevenueItemsChartByType: false,
      showTotalRevenueBranchesChart: false
    };
  },
  async created() {
    this.isLoadData = false;
    this.showLoader();
    await this.getData();
    await this.getDataDashboardAllTime();
    await this.getSmsBal();
    this.hideLoader();
    this.isLoadData = true;
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    const token = TokenService.getLocalAccessToken();
    this.merchant_name = localStorage
      .getItem("MERCHANT_ADMIN_NAME", "")
      .toUpperCase();
    if (!token || !code) {
      router.push("sign-in");
    }

    EventBus.on("ReloadHighestRevenueItemsChart", (index) => {
      if (this.dataDashBoard.highest_revenue_items_by_type && this.dataDashBoard.highest_revenue_items_by_type.type_ids.length) {
        let typeId = this.dataDashBoard.highest_revenue_items_by_type.type_ids[index];
        this.reloadChart(typeId);
      }
    });

    EventBus.on("ReloadItemRevenueByTypeChart", (index) => {
      if (this.dataDashBoard.total_revenue_branches && this.dataDashBoard.total_revenue_branches.branch_ids.length) {
        let branchId = this.dataDashBoard.total_revenue_branches.branch_ids[index];
        this.reloadChart(branchId, 2);
      }
    });
  },
  methods: {
    showTopupDashboard() {
      $("#btn-show-dashboard-component").click();
    },
    async reloadData(config) {
      this.showLoader();
      await this.getData(config);
      await this.getDataDashboardAllTime(config);
      this.hideLoader();
    },
    async getData(config = null) {
      this.showHighestRevenueItemsChart = false;
      this.showHighestRevenueItemsChartByType = false;
      this.showTotalRevenueBranchesChart = false;

      await DashboardService.getData({
        time_type: this.filter_time,
        config: config,
      }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.showHighestRevenueItemsChart = true;
            this.showHighestRevenueItemsChartByType = true;
            this.showTotalRevenueBranchesChart = true;
            this.dataDashBoard = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },
    async getDataDashboardAllTime(config = null) {
      await DashboardService.getDataDashboardAllTime({
        config: config
      }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.dataDashBoardAllTime = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },
    async reloadChart(id, type = 1) {
      this.showLoader();
      let formData = {
        time_type: this.filter_time
      };

      if (type === 1) {
        this.showHighestRevenueItemsChart = false;
        formData.type_id = id;
      } else {
        this.showHighestRevenueItemsChart = false;
        this.showHighestRevenueItemsChartByType = false;
        formData.branch_id = id;
      }

      await DashboardService.reloadChart(formData).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (type === 1) {
              this.showHighestRevenueItemsChart = true;
              this.showHighestRevenueItemsChartByType = true;
            } else {
              this.showHighestRevenueItemsChart = true;
              this.showHighestRevenueItemsChartByType = true;
              this.dataDashBoard.highest_revenue_items_by_type = response.data.data.highest_revenue_items_by_type;
            }

            this.dataDashBoard.highest_revenue_items = response.data.data.highest_revenue_items;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    async getSmsBal() {
      await SmsSetupService.getSmsBal().then(
      (response) => {
          if (response.data.result.isSuccess) {
              this.smsBal = response.data.data.smsBal;
              this.isShowWarningSms = response.data.data.isShowWarningSms;
              // this.begining = response.data.data.begining;
              // this.total_purchase = response.data.data.total_purchase;
              // this.total_use = response.data.data.total_use;
          } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
          }
      },
      (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
      }
      );
    },
    closeWarningSms() {
      this.isLoadData = false;
      this.showLoader();
      SmsSetupService.closeWarningSms().then(
      (response) => {
          if (response.data.result.isSuccess) {
            this.isShowWarningSms = 3;
            this.isLoadData = true;
          } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
          }
          this.hideLoader();
      },
      (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
      }
      );
    }
  },
  watch: {
    async filter_time(val) {
      this.showLoader();
      if (val) {
        await this.getData();
      }
      this.hideLoader();
    },
  },
};
</script>

<style scoped>
.standard-rule {
  color: black !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}
.btn-background-superior {
  background: #134f5c !important;
}

.mw-250px {
  max-width: 250px !important;
}

.data-over-time {
  padding-bottom: 50px;
  border-bottom: 5px solid #133067;
}

.list-data-box .b-content,
.list-chart .chart,
.list-ranking .t-ranking {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 15px;
  padding: 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
}
.list-chart .chart {
  height: 100%;
}
.list-data-box .b-content .b-data,
.list-ranking .t-ranking table thead tr th {
  color: #222;
}
.list-data-box .b-content div:last-child {
  margin-top: 15px;
}
.list-ranking .t-ranking table tbody tr td {
  font-size: 14px;
  font-weight: 400;
}
.list-data-box .b-content .b-data {
  font-weight: bold;
  font-size: 28px;
  text-align: end;
}
.b-mom-1 {
  color: #4cd137;
}
.b-mom-0 {
  color: #fbc531;
}
.b-mom--1 {
  color: #eb2f06;
}

.material-icons {
  width: 20px !important;
}

.separator-line {
  width: 1px;
  border: 1px solid #133067;
  margin: 10px;
}

.b-title {
  color: #133067;
  text-align: end;
  font-weight: 500 !important;
}

.d-title {
  font-size: 20px !important;
  color: #133067;
  margin-bottom: 20px;
  border-bottom: 1px solid #133067;
  font-weight: 500 !important;
}

.list-data-box > div:nth-child(n + 5) {
  margin-top: 40px;
}

@media only screen and (max-width: 991px) {
  .list-data-box > div:nth-child(n + 3),
  .list-ranking > div:last-child,
  .list-chart > div:nth-child(n + 2) {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 576px) {
  .list-data-box > div:nth-child(n + 2) {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .list-data-box.not-over-time > div:nth-child(n + 2) {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1400px) {
  .list-data-box.not-over-time > div:nth-child(n + 3) {
    margin-top: 40px;
  }
}

.data-box {
  position: relative;
}

.data-box .icon-box i {
  color: #FFFFFF;
}

.data-box .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: #1871bd;
  border-radius: 5px;
  top: -15px;
  left: 25px;
}
</style>
