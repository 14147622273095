<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddItem"
      id="show-modal-add-item"
    ></material-button>
    <material-button
      class="d-none"
      data-bs-dismiss="modal"
      data-bs-target="#modalAddItem"
      id="hide-modal-add-item"
    ></material-button>
    <div id="custom-overlay" class="custom-modal-overlay"></div>
    <div class="modal fade" id="modalAddItem" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ isEditItem ? "Edit Item" : "Add Item" }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div v-if="true">
              <div class="row d-flex justify-content-center mt-0">
                <ul id="general-tab" class="nav nav-tabs pe-0">
                  <li class="nav-item">
                    <a
                      :id="'nav_edit_item_1'"
                      class="nav-link active nav-edit-item d-flex align-items-center justify-content-center"
                      @click="changeTab(1)"
                    >
                      <i class="material-icons opacity-10 me-1"
                        >qr_code_scanner</i
                      >
                      <div class="mt-1">Online</div>
                    </a>
                  </li>
                  <li class="nav-item nav-edit-item">
                    <a
                      :id="'nav_edit_item_2'"
                      class="nav-link nav-edit-item d-flex align-items-center justify-content-center"
                      @click="changeTab(2)"
                      ><i class="material-icons opacity-10 me-1">computer</i>
                      <div class="mt-1">POS Setting</div></a
                    >
                  </li>
                  <li class="nav-item nav-edit-item">
                    <a
                      :id="'nav_edit_item_4'"
                      class="nav-link nav-edit-item d-flex align-items-center justify-content-center"
                      @click="changeTab(4)"
                      >
                      <div class="mt-1">Delivery Platform</div></a
                    >
                  </li>
                  <!-- <li class="nav-item nav-edit-item">
                    <a
                      :id="'nav_edit_item_3'"
                      class="nav-link nav-edit-item"
                      @click="changeTab(3)"
                      >Modifier</a
                    >
                  </li> -->
                </ul>
              </div>
              <div v-if="typeTab == 1">
                <div class="content-center">
                  <div class="mt-3" style="position: absolute;right: 31.5%;margin-top: 0px !important;">
                    <div class="dowload-img" v-show="urlImagePreview" style="width: 100px !important; height: 100px !important;">
                      <div id="preview" style="position: relative">
                        <img
                          class="image-url"
                          style="width: 100px !important; height: 100px !important;"
                          v-if="urlImagePreview"
                          :src="urlImagePreview"
                        />
                        <i
                          class="fa fa-times icon-close-sidenav-main danger"
                          v-if="urlImagePreview"
                          style="
                            position: absolute;
                            color: #f44335;
                            font-size: 24px;
                            right: 5px;
                            top: 6px;
                          "
                          aria-hidden="true"
                          @click="clearImage()"
                        ></i>
                        <div class="btn-edit-img">
                          <i
                            class="fas fa-pencil-alt text-dark"
                            v-if="urlImagePreview"
                            aria-hidden="true"
                            @click="uploadFile('image-item')"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      class="dowload-img"
                      style="width: 100px !important; height: 100px !important;"
                      @click="uploadFile('image-item')"
                      v-show="!urlImagePreview"
                    >
                      <material-input
                        style="display: none"
                        type="file"
                        id="image-item"
                        @change="handleFileUpload($event)"
                        accept="image/*"
                      />
                      <i class="material-icons css-icon-upload">backup</i>
                    </div>
                    <div
                      class="is-invalid d-flex align-items-center pe-2"
                      v-if="fieldRequired.isImageRequire"
                    >
                      {{ messageImageError }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <label class="fw-bold mb-0 mt-1 me-3" for>Enable</label>
                    <div class="d-flex">
                      <div
                        :class="{ active: !item.is_disabled }"
                        class="toggle_container pt-1"
                      >
                        <MaterialToggleButton
                          labelEnableText
                          labelDisableText
                          id="event_handle_2"
                          v-on:change="triggerEvent"
                          class="mb-0"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="fw-bold" for>
                      Item No
                      <span class="text-danger ms-2">*</span>
                    </label>
                    <material-input
                      v-model="item.item_no"
                      type="text"
                      id="item_no"
                      name="item_no"
                    />
                    <span
                      class="is-invalid"
                      v-if="fieldRequired.isItemNameNoRequired"
                      >The field is required.</span
                    >
                  </div>
                  <div class="mt-3">
                    <label class="fw-bold" for>
                      Item Name
                      <span class="text-danger ms-2">*</span>
                    </label>
                    <material-input
                      v-model="item.item_name"
                      type="text"
                      id="item_name"
                      name="item_name"
                      placeholder="Online ordering name"
                      @change="inputItemName()"
                    />
                    <span
                      class="is-invalid"
                      v-if="fieldRequired.isItemNameRequired"
                      >The field is required.</span
                    >
                  </div>
                  <!-- <div class="mt-3">
                    <label class="fw-bold" for> Item Price </label>
                    <material-input
                      @keydown.prevent.up="preventUpDown"
                      @keydown.prevent.down="preventUpDown"
                      v-model="item.price"
                      id="item_price"
                      name="item_price"
                      extraClass="p-2"
                      type="number"
                      size="lg"
                    />
                    <span
                      class="is-invalid"
                      v-if="fieldRequired.isItemPriceRequired"
                      >Number must be greater than or equal to 0.</span
                    >
                  </div> -->
                  <div class="mt-3">
                    <label class="fw-bold" for>
                      Description
                      <!-- <span class="text-danger ms-2">*</span> -->
                    </label>
                    <material-textarea
                      rows="3"
                      v-model="item.description"
                      id="item_description"
                      name="item_description"
                      placeholder="Ingredients, foreign language name etc"
                      @input="inputItemDescription()"
                    />
                    <!-- <span
                      class="is-invalid"
                      v-if="fieldRequired.isItemDescriptionRequired"
                      >The field is required.</span
                    > -->
                  </div>
                  <div class="mt-3" v-if="item.variations.length == 0">
                    <h3 class="title-page fs-16 mb-0">PRICE LIST</h3>
                    <div class="table-responsive p-0 mt-3">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <!-- <th class="w-30">Label</th> -->
                            <th class="w-35">Description</th>
                            <th class="w-20">Price</th>
                            <!-- <th></th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(price, index) in item.others_price"
                            :key="index"
                          >
                            <!-- <td class="tex-end">{{ price.name }}</td> -->
                            <td class="tex-end">
                              <!-- <material-input
                                v-model="price.description"
                                type="text"
                                :id="'price_description_' + index"
                                :name="'price_description_' + index"
                                :disabled="true"
                              /> -->
                              <span>
                                {{ price.description }}
                                <!-- Add a red * for PRICE 1 -->
                                <span v-if="price.price_key === 'price_1'" class="text-danger">*</span>
                              </span>
                            </td>
                            <td class="tex-end">
                              <material-input
                                @keydown.prevent.up="preventUpDown"
                                @keydown.prevent.down="preventUpDown"
                                v-model="price.price"
                                :id="'other_price_' + index"
                                :name="'other_price_' + index"
                                extraClass="p-2"
                                type="number"
                                size="lg"
                                @input="inputItemPrice($event, index)"
                              />
                              <!-- <span
                                class="is-invalid"
                                v-if="price.price_key === 'price_1' && fieldRequired.isPrice1Required"
                              >
                                The field is required and must be greater than 0.
                              </span> -->
                            </td>
                            <!-- <td>
                              <div class="d-flex">
                                <div
                                  :class="{ active: price.status }"
                                  class="toggle_container pt-1"
                                >
                                  <MaterialToggleButton
                                    labelEnableText
                                    labelDisableText
                                    :id="'price-status-' + index"
                                    v-on:change="triggerEventPrice(index)"
                                    class="mb-0"
                                  />
                                </div>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="fw-bold" for>
                      Item Mode
                      <span class="text-danger ms-2">*</span>
                    </label>
                    <Multiselect
                      v-model="item.item_type"
                      :options="itemTypes"
                      placeholder="Select item mode"
                      :canClear="false"
                    />
                  </div>
                  <div class="mt-3">
                    <label class="fw-bold" for>Tags & allergens</label>
                    <Multiselect
                      :createOption="true"
                      :searchable="true"
                      :mode="'tags'"
                      v-model="item.tags"
                      :options="tagList"
                      :canClear="false"
                      placeholder="Enter some Tags & allergens"
                    />
                  </div>
                  <div class="mt-5">
                    <h5 class="tag-a-custom">Ordering Rules</h5>
                  </div>
                  <div class="row mt-3">
                    <div class="col-6">
                      <label class="fw-bold" for>Min order limit</label>
                      <div class="d-flex">
                        <material-input
                          @keydown.prevent.up="preventUpDown"
                          @keydown.prevent.down="preventUpDown"
                          v-model="item.min_order_qty"
                          id="min_order_qty"
                          name="min_order_qty"
                          extraClass="p-2"
                          type="number"
                          size="lg"
                        />
                      </div>
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isItemMinOrderLimitRequired"
                        >Number must be greater than or equal to 0.</span
                      >
                    </div>
                    <div class="col-6">
                      <label class="fw-bold" for>Max order limit</label>
                      <div class="d-flex">
                        <material-input
                          @keydown.prevent.up="preventUpDown"
                          @keydown.prevent.down="preventUpDown"
                          v-model="item.max_order_qty"
                          id="max_order_qty"
                          name="max_order_qty"
                          extraClass="p-2"
                          type="number"
                          size="lg"
                        />
                      </div>
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isItemMinOrderLimitRequired"
                        >Number must be greater than or equal to 0.</span
                      >
                    </div>
                  </div>
                  <div class="d-flex align-item-center mt-4">
                    <label class="fw-bold mb-0 mt-2 me-3" for
                      >Buy one get one free</label
                    >
                    <div class="d-flex">
                      <div
                        :class="{ active: item.is_buy_one_get_one_free }"
                        class="toggle_container pt-1"
                      >
                        <MaterialToggleButton
                          labelEnableText
                          labelDisableText
                          id="event_handle"
                          v-on:change="triggerEventFreeItem"
                          class="mb-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price-box" v-if="item.variations.length">
                  <h3 class="title-page fs-16 mb-0">PRICE LIST</h3>
                  <!-- <div class="d-flex justify-content-end mt-3">
                    <div class="input-group mw-300px">
                      <div
                        class="form-outline w-100"
                        style="width: calc(100% - 75px) !important"
                      >
                        <input
                          type="text"
                          name="variation_name"
                          id="variation_name"
                          class="form-control"
                          style="
                            border: 1px solid #d2d6da;
                            padding: 8px !important;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                          "
                          placeholder="Name the Variation"
                          @keydown.enter="addVariation()"
                        />
                      </div>
                      <div
                        type="button"
                        class="btn mb-0"
                        style="border: 1px solid #d2d6da"
                        @click="addVariation()"
                      >
                        Add
                      </div>
                    </div>
                    <span class="is-invalid d-none" id="variation_name_error"
                      >The field is required.</span
                    >
                  </div> -->
                  <div class="price-list">
                    <div
                      class="table-responsive p-0 mt-3 table-variation"
                      v-if="item.variations.length"
                    >
                      <table
                        class="table align-items-center mb-0 table-variation-price"
                      >
                        <thead>
                          <tr>
                            <th>Variation</th>
                            <th>Variation No</th>
                            <th>Description</th>
                            <th>Image</th>
                            <template
                              v-for="(price, index) in item.variations[0]
                                .prices"
                              :key="index"
                            >
                              <th class="variation-price">
                                {{ price.name }}<br />
                                <small>{{
                                  priceList[index].description ?? ""
                                }}</small>
                              </th>
                            </template>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-for="(variation, index) in item.variations"
                            :key="index"
                          >
                            <tr>
                              <td class="tex-end">
                                <material-input
                                  v-model="variation.name"
                                  type="text"
                                  :id="'variation_name_' + index"
                                  :name="'variation_name_' + index"
                                />
                              </td>
                              <td class="tex-end">
                                <material-input
                                  v-model="variation.variation_no"
                                  type="text"
                                  :id="'variation_no_' + index"
                                  :name="'variation_no_' + index"
                                />
                              </td>
                              <td class="tex-end">
                                <material-input
                                  v-model="variation.description"
                                  type="text"
                                  :id="'variation_option_description_' + index"
                                  :name="
                                    'variation_option_description_' + index
                                  "
                                />
                              </td>
                              <td class="tex-end">
                                <div>
                                  <div
                                    class="dowload-img variation-option-dowload-img"
                                    v-show="variation.image_preview"
                                  >
                                    <div
                                      id="preview"
                                      style="position: relative"
                                    >
                                      <img
                                        class="image-url"
                                        v-if="variation.image_preview"
                                        :src="variation.image_preview"
                                      />
                                      <div class="btn-edit-img">
                                        <i
                                          class="fas fa-pencil-alt text-dark"
                                          v-if="variation.image_preview"
                                          aria-hidden="true"
                                          @click="
                                            uploadFile(
                                              'variation-option-image-' + index
                                            )
                                          "
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="dowload-img variation-option-dowload-img"
                                    style="height: 70px !important;"
                                    @click="
                                      uploadFile(
                                        'variation-option-image-' + index
                                      )
                                    "
                                    v-show="!variation.image_preview"
                                  >
                                    <material-input
                                      style="display: none"
                                      type="file"
                                      :id="'variation-option-image-' + index"
                                      @change="handleFileUpload($event, index)"
                                      accept="image/*"
                                    />
                                    <i class="material-icons css-icon-upload"
                                      >backup</i
                                    >
                                  </div>
                                </div>
                              </td>
                              <template
                                v-for="(price, index2) in variation.prices"
                                :key="index2"
                              >
                                <td class="tex-end">
                                  <material-input
                                    @keydown.prevent.up="preventUpDown"
                                    @keydown.prevent.down="preventUpDown"
                                    v-model="price.price"
                                    :id="
                                      'variation_option_price_' +
                                      index +
                                      '' +
                                      index2
                                    "
                                    :name="
                                      'variation_option_price_' +
                                      index +
                                      '' +
                                      index2
                                    "
                                    extraClass="p-2"
                                    type="number"
                                    size="lg"
                                  />
                                </td>
                              </template>
                              <td>
                                <div class="d-flex">
                                  <!-- <div
                                    :class="{ active: variation.status }"
                                    class="toggle_container pt-1"
                                  >
                                    <MaterialToggleButton
                                      labelEnableText
                                      labelDisableText
                                      :id="'variation-price-status-' + index"
                                      v-on:change="triggerVariation(index)"
                                      class="mb-0"
                                    />
                                  </div> -->
                                  <div class="ms-3 d-flex align-items-center">
                                    <i
                                      class="fas fa-trash-alt cursor-pointer ms-2"
                                      @click="removeVariation(index)"
                                    ></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <div
                              class="is-invalid"
                              v-if="
                                fieldVariationRequired[index] &&
                                fieldVariationRequired[index].isImageRequire
                              "
                            >
                              {{ messageImageError }}
                            </div>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="table-responsive p-0 mt-3" v-if="1 > 2">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <!-- <th class="w-30">Label</th> -->
                            <th class="w-35">Description</th>
                            <th class="w-20">Price</th>
                            <!-- <th></th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(price, index) in item.others_price"
                            :key="index"
                          >
                            <!-- <td class="tex-end">{{ price.name }}</td> -->
                            <td class="tex-end">
                              <material-input
                                v-model="price.description"
                                type="text"
                                :id="'price_description_' + index"
                                :name="'price_description_' + index"
                                :disabled="true"
                              />
                            </td>
                            <td class="tex-end">
                              <material-input
                                @keydown.prevent.up="preventUpDown"
                                @keydown.prevent.down="preventUpDown"
                                v-model="price.price"
                                :id="'other_price_' + index"
                                :name="'other_price_' + index"
                                extraClass="p-2"
                                type="number"
                                size="lg"
                              />
                            </td>
                            <!-- <td>
                              <div class="d-flex">
                                <div
                                  :class="{ active: price.status }"
                                  class="toggle_container pt-1"
                                >
                                  <MaterialToggleButton
                                    labelEnableText
                                    labelDisableText
                                    :id="'price-status-' + index"
                                    v-on:change="triggerEventPrice(index)"
                                    class="mb-0"
                                  />
                                </div>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="typeTab == 2">
                <div class="content-center">
                  <div class="mt-3">
                    <div
                      class="d-flex justify-content-between align-items-center mb-2"
                    >
                      <label class="fw-bold mb-0" for> POS Name </label>
                      <div class="d-flex align-items-center">
                        <material-checkbox
                          @change="checkboxChange(item.same_item_name, 'name')"
                          class="align-items-center checkbox-custom mb-0 ms-3"
                          v-model="item.same_item_name"
                          :checked="item.same_item_name"
                        ></material-checkbox>
                        <div class="mt-1 ms-2">Same as Item Name</div>
                      </div>
                    </div>
                    <material-input
                      v-model="item.pos_name"
                      type="text"
                      id="pos_name"
                      name="pos_name"
                      :disabled="item.same_item_name"
                    />
                  </div>
                  <div class="mt-3">
                    <div
                      class="d-flex justify-content-between align-items-center mb-2"
                    >
                      <label class="fw-bold mb-0" for> POS Name 2 </label>
                      <div class="d-flex align-items-center">
                        <material-checkbox
                          @change="
                            checkboxChange(
                              item.same_item_description,
                              'description'
                            )
                          "
                          class="align-items-center checkbox-custom mb-0 ms-3"
                          v-model="item.same_item_description"
                          :checked="item.same_item_description"
                        ></material-checkbox>
                        <div class="mt-1 ms-2">Same as Description</div>
                      </div>
                    </div>
                    <material-input
                      v-model="item.pos_description"
                      type="text"
                      id="pos_description"
                      name="pos_description"
                      :disabled="item.same_item_description"
                    />
                  </div>
                  <div class="mt-3">
                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <label class="fw-bold mb-0" for>Report group</label>
                      <a
                        @click="gotoTypeAndGroupPage()"
                        class="text-decoration-underline tag-a-custom"
                        >Manage</a
                      >
                    </div>
                    <div class="d-flex">
                      <Multiselect
                        v-model="item.type_id"
                        :options="types"
                        :canClear="false"
                        placeholder="Type"
                        @change="selectType"
                      />
                      <Multiselect
                        v-model="item.group_id"
                        :options="groups"
                        :canClear="false"
                        class="ms-2"
                        placeholder="Group"
                        :disabled="!item.type_id"
                      />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-6">
                      <label class="fw-bold" for> Button Color </label>
                      <div>
                        <color-input
                          v-model="item.button_color"
                          format="hex"
                          position="right bottom"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <label class="fw-bold" for> Font Color </label>
                      <div>
                        <color-input
                          v-model="item.font_color"
                          format="hex"
                          position="right bottom"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label class="fw-bold" for> Button Position </label>
                    <div class="d-flex">
                      <material-input
                        @keydown.prevent.up="preventUpDown"
                        @keydown.prevent.down="preventUpDown"
                        v-model="item.button_position"
                        id="button_position"
                        name="button_position"
                        extraClass="p-2"
                        type="number"
                        size="lg"
                      />
                    </div>
                    <span
                      class="is-invalid"
                      v-if="fieldRequired.isItemButtonPositionRequired"
                      >Number must be greater than or equal to 0.</span
                    >
                  </div>
                </div>
              </div>
              <div v-if="typeTab == 3">
                <div class="modifier-box mt-3">
                  <div class="d-flex align-items-center">
                    <h3 class="title-page fs-16 mb-0">MODIFIER</h3>
                    <material-button
                      class="ms-3 btn btn-sm btn-background"
                      @click="addOptionGroup()"
                      >Add Option Group</material-button
                    >
                  </div>
                  <div v-for="(group, index) in groupsSelect" :key="index">
                    <div class="title-page fs-16 mt-4">
                      {{ "OPTION GROUP - " + group.name.toUpperCase() }}
                    </div>
                    <div
                      v-for="(
                        section, index2
                      ) in group.modifier_option_group_section"
                      :key="index2"
                    >
                      <div class="title-page fs-16 mt-3">
                        {{ section.title }}
                      </div>
                      <div class="d-flex justify-content-between mt-3">
                        <div>Min {{ group.min_qty }}</div>
                        <div>Max {{ group.max_qty }}</div>
                        <div>Item Max {{ group.item_max_qty }}</div>
                        <div>
                          Dining Option:
                          {{ diningOptionLabel[group.dining_option - 1] }}
                        </div>
                        <div class="d-flex">
                          Remark
                          <material-checkbox
                            :checked="section.is_remark"
                            class="align-items-center checkbox-custom mb-0 ms-3"
                            :isDisabled="true"
                          ></material-checkbox>
                        </div>
                        <div class="d-flex">
                          Required
                          <material-checkbox
                            :checked="section.is_required"
                            class="align-items-center checkbox-custom mb-0 ms-3"
                            :isDisabled="true"
                          ></material-checkbox>
                        </div>
                      </div>
                      <div class="modifier-list">
                        <div class="table-responsive p-0 mt-3">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Price</th>
                                <th>Display</th>
                              </tr>
                            </thead>
                            <tbody v-if="section.items.length">
                              <tr
                                v-for="(item, index3) in section.items"
                                :key="index3"
                              >
                                <td class="tex-end">
                                  {{ item.item_no_format }}
                                </td>
                                <td class="tex-end">
                                  {{ item.item_name_format }}
                                </td>
                                <td>
                                  <img
                                    :src="item.image"
                                    width="70"
                                    height="70"
                                  />
                                </td>
                                <td class="tex-end">
                                  {{ item.item_price_format }}
                                </td>
                                <td>
                                  <div class="d-flex">
                                    <div
                                      :class="{ active: item.modifier_status }"
                                      class="toggle_container pt-1"
                                    >
                                      <MaterialToggleButton
                                        labelEnableText
                                        labelDisableText
                                        :id="
                                          'modifier-status-' +
                                          index +
                                          '_' +
                                          index2 +
                                          '_' +
                                          index3
                                        "
                                        v-on:change="
                                          triggerEventAppliedPrice(
                                            index,
                                            index2,
                                            index3
                                          )
                                        "
                                        class="mb-0"
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="5" class="text-center">
                                  No item available
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="typeTab == 4" class="content-center"> 
                <div class="table-responsive p-0 mt-3">
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th class="w-5"></th>
                        <th class="w-20">Platform Name</th>
                        <th class="w-20">Price</th>
                        <!-- <th></th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(price, index) in item.others_price_delivery_platform"
                        :key="index"
                      >
                        <td class="tex-end">{{ index + 1 }}</td>
                        <td class="tex-end">
                          <material-input
                            v-model="price.description"
                            :disabled="index < 3"
                            type="text"
                            :id="'price_description1_' + index"
                            :name="'price_description1_' + index"
                            @input="validatePlatformName(price, index)"
                          />
                          <small v-if="errorsPlatformName[index]" class="text-danger" style="font-size: 12px;">
                            {{ errorsPlatformName[index] }}
                          </small>
                        </td>
                        <td class="tex-end">
                          <div style="display: flex;align-items: center;">
                            <span>$</span>
                            <material-input
                              @keydown.prevent.up="preventUpDown"
                              @keydown.prevent.down="preventUpDown"
                              v-model="price.price"
                              :id="'other_price1_' + index"
                              :name="'other_price1_' + index"
                              extraClass="p-2"
                              type="number"
                              size="lg"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex mt-3">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveItem()"
              >
                {{ isEditItem ? "Save" : "Add Item" }}
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <AddOptionGroup
      :keyReload="keyReloadModalAddOptionGroup"
      :setId="menusetId"
      :itemId="isEditItem"
      :groupIdsSelect="groupIdsSelect"
      :groupsSelect="groupsSelect"
      @handle-add-option-group="handleAddOptionGroup"
    ></AddOptionGroup>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ItemService from "../services/product/item.service";
import MenusetService from "../services/product/menuset.service";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ColorInput from "vue-color-input";
import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "@vueform/multiselect/themes/default.css";
import AddOptionGroup from "@/components/AddOptionGroup.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import Modal from "bootstrap/js/dist/modal";

export default {
  name: "add-item",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    MaterialCheckbox,
    ColorInput,
    MaterialToggleButton,
    Multiselect,
    AddOptionGroup,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    menusetId: {
      type: Number,
      default: 0,
    },
    categoryId: {
      type: Number,
      default: 0,
    },
    groupId: {
      type: Number,
      default: 0,
    },
    subGroupId: {
      type: Number,
      default: 0,
    },
    isEditItem: {
      type: Number,
      default: 0,
    },
    displayOrder: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoadData: false,
      snackbar: null,
      message: "",
      urlImagePreview: null,
      messageImageError: "",
      fieldRequired: [],
      isActive: true,
      checkArrow: false,
      checkArrowTable: false,
      loaderActive: false,
      priceList: [],
      priceListDeliveryPlatform: [],
      branchIdSelectDelete: 0,
      keyReloadModalAddOptionGroup: "key-reload-modal-add-option-group",
      groupIdsSelect: [],
      groupsSelect: [],
      groups: [],
      dataGroupByType: [],
      types: [],
      tags: [
        {
          label: "Cheese",
          value: 1,
        },
        {
          label: "Seafood",
          value: 2,
        },
      ],
      itemTypes: [
        {
          label: "Fixed Price",
          value: 0,
        },
        {
          label: "By Price",
          value: 1,
        },
        {
          label: "By Weight",
          value: 2,
        },
        {
          label: "By Quantity",
          value: 3,
        },
        {
          label: "Set Menu",
          value: 4,
        },
        {
          label: "Multiple Price",
          value: 5,
        },
      ],
      diningOptionLabel: ["All", "Dine in", "Takeaway"],
      item: {
        item_no: "",
        item_name: "",
        image: null,
        description: "",
        pos_name: "",
        pos_description: "",
        barcode: "",
        price: 0,
        min_order_qty: 0,
        max_order_qty: 1,
        is_disabled: false,
        item_type: 0,
        group_id: 0,
        type_id: 0,
        button_color: "#1871bd",
        font_color: "#1871bd",
        button_position: 0,
        others_price: [],
        others_price_delivery_platform: [],
        delivery_tax: 0,
        takeaway_tax: 0,
        eat_in_tax: 0,
        category_id: this.categoryId,
        menuset_id: this.menusetId,
        group_item_id: this.groupId,
        sub_group_item_id: this.subGroupId,
        is_buy_one_get_one_free: false,
        variations: [],
        tag: 0,
        tags: [],
        variationIdDelete: [],
        modifier_option_group_ids: [],
        same_item_name: true,
        same_item_description: true,
      },
      categories: [],
      fieldVariationRequired: [],
      isEditVariation: false,
      typeTab: 1,
      tagList: [],
      errorsPlatformName: [],
    };
  },
  methods: {
    changeTab(type) {
      this.typeTab = type;
      $("#general-tab .nav-edit-item").removeClass("active");
      $("#nav_edit_item_" + type).addClass("active");
    },

    hideEditItem() {
      this.$emit("hide-edit-item");
    },

    handleChangeTag(tags) {
      this.item.tags = tags;
    },
    uploadFile(id) {
      $("#" + id).click();
    },

    clearImage() {
      this.item.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
    },

    handleFileUpload(event, index = -1) {
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        if (index >= 0) {
          this.item.variations[index].image = null;
          this.item.variations[index].image_preview = null;
        } else {
          this.item.image = null;
          this.urlImagePreview = null;
        }
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          if (index >= 0) {
            this.fieldVariationRequired[index].isImageRequire = true;
          } else {
            this.fieldRequired.isImageRequire = true;
          }
          this.messageImageError = `File must be an image.`;
        } else {
          if (index >= 0) {
            this.item.variations[index].image = file;
            this.item.variations[index].image_preview = URL.createObjectURL(
              file
            );
          } else {
            this.item.image = file;
            this.urlImagePreview = URL.createObjectURL(file);
          }
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                if (index >= 0) {
                  this.fieldVariationRequired[index].isImageRequire = true;
                } else {
                  this.fieldRequired.isImageRequire = true;
                }
              } else {
                if (index >= 0) {
                  this.fieldVariationRequired[index].isImageRequire = false;
                } else {
                  this.fieldRequired.isImageRequire = false;
                }
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        if (index >= 0) {
          this.item.variations[index].image = null;
          this.item.variations[index].image_preview = null;
        } else {
          this.item.image = null;
          this.urlImagePreview = null;
        }
        this.messageImageError = "";
      }
    },

    clearFormData() {
      this.item = {
        item_no: "",
        item_name: "",
        image: null,
        description: "",
        pos_name: "",
        pos_description: "",
        barcode: "",
        price: 0,
        min_order_qty: 0,
        max_order_qty: 1,
        is_disabled: false,
        item_type: 0,
        group_id: 0,
        type_id: 0,
        button_color: "#1871bd",
        font_color: "#1871bd",
        button_position: 0,
        others_price: this.priceList,
        others_price_delivery_platform: this.priceListDeliveryPlatform,
        delivery_tax: 0,
        takeaway_tax: 0,
        eat_in_tax: 0,
        category_id: this.categoryId,
        menuset_id: this.menusetId,
        group_item_id: this.groupId,
        sub_group_item_id: this.subGroupId,
        is_buy_one_get_one_free: false,
        variations: [],
        modifier_option_group_ids: [],
        tag: 0,
        tags: [],
        variationIdDelete: [],
        same_item_name: true,
        same_item_description: true,
      };
      this.groupsSelect = [];
      this.urlImagePreview = false;
      this.messageImageError = "";
      $("#image-item").val("");
      this.snackbar = null;
      this.message = "";
      this.changeTab(1);
    },

    saveItem() {
      if (this.processValidate()) {
        this.showLoader();
        this.item.modifier_option_groups = null;
        if (this.item.id > 0) {
          // update
          ItemService.update(this.item.id, this.item).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update Item Success";
                this.setTimeOutNotification();
                this.$emit("reload-list-category");
                $("#hide-modal-add-item").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error.response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          // add
          this.item.display_order = this.displayOrder;
          ItemService.store(this.item).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Add Item Success";
                this.setTimeOutNotification();
                this.$emit("reload-list-category");
                $("#hide-modal-add-item").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error.response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      } else {
        if(this.errorsPlatformName && this.errorsPlatformName.length > 0) {
          this.typeTab = 4;
        } else {
          this.typeTab = 1;
        }
        $(".modal-body").scrollTop(0);
      }
    },

    async getListTag() {
      await ItemService.getListTag().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.tagList = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    async getReportGroup() {
      await ItemService.getReportGroup().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.types = response.data.data.dataTypeDropdown;
            this.dataGroupByType = response.data.data.dataGroupDropdown;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    selectType(value) {
      this.item.group_id = 0;
      this.groups = this.dataGroupByType[value];
    },

    async getItemDetail() {
      await ItemService.itemDetail(this.isEditItem, this.menusetId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.item = response.data.data;
            if (this.item.others_price.length == 0) {
              this.item.others_price = this.priceList;
            }
            if (this.item.others_price_delivery_platform.length == 0) {
              this.item.others_price_delivery_platform = this.priceListDeliveryPlatform;
            }
            this.groups = this.dataGroupByType[this.item.type_id] ?? [];
            this.item.variations.forEach(() => {
              this.fieldVariationRequired.push({
                isNameRequire: false,
                isImageRequire: false,
              });
            });
            this.groupIdsSelect = this.item.modifier_option_group_ids;
            this.item.modifier_option_groups.forEach((group) => {
              if (group["modifier_option_group"]) {
                this.groupsSelect.push(group["modifier_option_group"]);
              }
            });
            this.urlImagePreview = this.item.image;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async getPriceList() {
      await MenusetService.getPriceList({}).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.priceList = response.data.data;

            if (!this.isEditItem) {
              this.item.others_price = this.priceList;
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    async getPriceListDeliveryPlatform() {
      await MenusetService.getPriceListDeliveryPlatform({}).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.priceListDeliveryPlatform = response.data.data;

            if (!this.isEditItem) {
              this.item.others_price_delivery_platform = this.priceListDeliveryPlatform;
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    resetRequired() {
      this.fieldRequired.isItemNameNoRequired = false;
      this.fieldRequired.isItemNameRequired = false;
      this.fieldRequired.isItemDescriptionRequired = false;
      this.fieldRequired.isItemMinOrderLimitRequired = false;
      this.fieldRequired.isItemMaxOrderLimitRequired = false;
      // this.fieldRequired.isPrice1Required = false;
      // this.fieldRequired.isItemPriceRequired = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.item.item_no == "" || this.item.item_no == null) {
        this.fieldRequired.isItemNameNoRequired = true;
        isValid = false;
      }

      if (this.item.item_name == "" || this.item.item_name == null) {
        this.fieldRequired.isItemNameRequired = true;
        isValid = false;
      }

      // if (this.item.description == "" || this.item.description == null) {
      //   this.fieldRequired.isItemDescriptionRequired = true;
      //   isValid = false;
      // }

      // if (this.item.price === "" || this.item.price < 0) {
      //   this.fieldRequired.isItemPriceRequired = true;
      //   isValid = false;
      // }

      if (this.item.min_order_qty === "" || this.item.min_order_qty < 0) {
        this.fieldRequired.isItemMinOrderLimitRequired = true;
        isValid = false;
      }

      if (this.item.max_order_qty === "" || this.item.max_order_qty < 0) {
        this.fieldRequired.isItemMaxOrderLimitRequired = true;
        isValid = false;
      }

      if (this.item.button_position === "" || this.item.button_position < 0) {
        this.fieldRequired.isItemButtonPositionRequired = true;
        isValid = false;
      }

      // const price1 = this.item.others_price.find(
      //   (price) => price.price_key === "price_1"
      // );

      // if (!price1 || price1.price === "" || price1.price <= 0) {
      //   this.fieldRequired.isPrice1Required = true;
      //   isValid = false;
      // }

      if (this.errorsPlatformName && this.errorsPlatformName.some(error => error !== null && error !== undefined)) {
        isValid = false;
      }

      this.item.variations.map((variation, index) => {
        if (variation.name == "" || variation.name == null) {
          this.fieldVariationRequired[index].isNameRequire = true;
          isValid = false;
          return;
        }
      });

      return isValid;
    },

    inputItemPrice(event, index) {
      let price = parseFloat(event.target.value);
      
      if (price < 0) {
        this.item.others_price[index].price = 0;
      }
    },

    formatDate(date) {
      return new Date(date).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },

    triggerEvent(value) {
      if (typeof value == "boolean") {
        this.item.is_disabled = !value;
      }
    },

    triggerEventFreeItem(value) {
      if (typeof value == "boolean") {
        this.item.is_buy_one_get_one_free = !value;
      }
    },

    triggerEventPrice(index) {
      var value = $("#price-status-" + index + "_button").is(":checked");
      if (typeof value == "boolean") {
        this.item.others_price[index].status = !value;
      }
    },

    triggerEventAppliedPrice(index, index2, index3) {
      var value = $(
        "#modifier-status-" + index + "_" + index2 + "_" + index3 + "_button"
      ).is(":checked");
      if (typeof value == "boolean") {
        this.groupsSelect[index].modifier_option_group_section[index2].items[
          index3
        ].modifier_status = !value;
      }
    },

    triggerVariation(index) {
      var value = $("#variation-price-status-" + index + "_button").is(
        ":checked"
      );
      if (typeof value == "boolean") {
        this.item.variations[index].status = !value;
      }
    },

    addOptionGroup() {
      this.keyReloadModalAddOptionGroup = Math.random();
      const overlay = document.getElementById("custom-overlay");
      overlay?.classList.add("show");
      const modalElement = document.getElementById("addOptionGroup");
      if (modalElement) {
        const modal = new Modal(modalElement);
        modal.show();
        modalElement.addEventListener("hidden.bs.modal", () => {
          overlay?.classList.remove("show");
        });
      }
    },

    handleAddOptionGroup(ids, groups) {
      this.groupIdsSelect = ids;
      this.groupsSelect = groups;
      this.item.modifier_option_group_ids = ids;
    },

    renameVariation(index) {
      var name = $("#variation_name_" + index).val();
      this.item.variations.name = name;
      if (name) {
        // $("#variation_name_error").addClass("d-none");
      } else {
        // $("#variation_name_error").removeClass("d-none");
      }
    },

    addVariation() {
      var name = $("#variation_name").val();
      if (name) {
        $("#variation_name").val("");
        $("#variation_name_error").addClass("d-none");
        var prices = [];
        this.priceList.forEach((price) => {
          prices.push({
            id: Math.random,
            price: 0,
            price_key: price.price_key,
            name: price.name,
            description: price.description,
            status: price.status,
          });
        });

        this.item.variations.push({
          variation_no: (Math.random() + 1).toString(36).substring(7),
          name: name,
          description: "",
          image: "",
          image_preview: "",
          prices: prices,
          status: 1,
        });

        this.fieldVariationRequired.push({
          isNameRequire: false,
          isImageRequire: false,
        });
      } else {
        $("#variation_name_error").removeClass("d-none");
      }
    },

    removeVariation(index) {
      if (this.item.variations.length > 0) {
        if (this.item.variations[index] && this.item.variations[index].id) {
          this.item.variationIdDelete.push(this.item.variations[index].id);
        }
        this.item.variations.splice(index, 1);
        this.fieldVariationRequired.splice(index, 1);
      }

      if (this.item.variations.length == 0) {
        if (this.item.others_price.length == 0) {
          var prices = [];
          this.priceList.forEach((price) => {
            prices.push({
              id: Math.random(),
              price: 0,
              price_key: price.price_key,
              name: price.name,
              description: price.description,
              status: price.status,
            });
          });
          this.item.others_price = prices;
        }
      }
    },

    preventUpDown(event) {
      // Check if the up or down arrow key was pressed
      if (event.keyCode === 38 || event.keyCode === 40) {
        // Prevent the default behavior of the key event
        event.preventDefault();
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    gotoTypeAndGroupPage() {
      $("#hide-modal-add-item").click();
      this.$router.push("/report-group");
    },

    checkboxChange(value, field = "name") {
      if (field == "name") {
        if (value) {
          this.item.pos_name = this.item.item_name;
        } else {
          this.item.pos_name = "";
        }
      }

      if (field == "description") {
        if (value) {
          this.item.pos_description = this.item.description;
        } else {
          this.item.pos_description = "";
        }
      }
    },

    inputItemName() {
      if (this.item.same_item_name) {
        this.item.pos_name = this.item.item_name;
      }
    },

    inputItemDescription() {
      if (this.item.same_item_description) {
        this.item.pos_description = this.item.description;
      }
    },

    validatePlatformName(price, index) {
      // Check if the description exceeds 20 characters
      if (price.description.length > 20) {
        this.errorsPlatformName[index] = 'Max 20 characters';
      } else {
        this.errorsPlatformName[index] = null;
      }
    },
  },
  watch: {
    async keyReload() {
      this.showLoader();
      this.resetRequired();
      this.clearFormData();
      this.isLoadData = false;

      await this.getPriceList();
      await this.getPriceListDeliveryPlatform();
      await this.getReportGroup();
      await this.getListTag();

      if (this.isEditItem) {
        await this.getItemDetail();
      }

      this.hideLoader();
      this.isLoadData = true;
    }
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}
.modal-body .row {
  margin-top: 10px;
}
.modal-dialog {
  max-width: 950px;
}
.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 150px;
  height: 150px;
  overflow-y: auto;
  position: relative;
}
.variation-option-dowload-img {
  width: 70px;
  height: unset !important;
}
.variation-option-dowload-img .css-icon-upload {
  margin-top: 0;
}
.variation-option-dowload-img .image-url {
  width: 70px;
  height: 70px;
}
.variation-option-dowload-img .btn-edit-img {
  top: 0px;
  right: 0px;
}
.css-icon-upload {
  font-size: 45px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-url {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.col-mg {
  margin-bottom: 20px;
}
.modal-width {
  min-width: 60%;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.icon-arrow {
  position: absolute;
  transform: translateY(-11px);
  font-size: 45px;
}
.cate-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d5d6d8;
  padding: 15px 0;
  border-radius: 5px;
}
.cate-card {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}
.item-code {
  background-color: #d2d2d2;
  margin-left: 15px;
  margin-top: 4px;
}
.items-table-code {
  background-color: #d2d2d2;
  line-height: unset;
  transform: translateY(-3px);
}
.itme-product {
  color: #d2d2d2;
  margin-left: 15px;
}
.table-product {
  overflow-x: auto !important;
}
.remove-price {
  margin-top: 2rem;
}
.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}
.cursor-pointer {
  cursor: pointer;
}
.variations-box,
.price-box,
.modifier-box {
  border: 1px solid #d5d6d8;
  border-radius: 5px;
  padding: 15px 25px;
  margin: 30px 0;
}

.variation-info {
  padding-top: 20px;
  padding-bottom: 20px;
}

.variation-info .btn {
  min-width: 75px !important;
}

.variation-info:not(:last-child) {
  border-bottom: 1px solid #d2d6da;
}

.table-variation-price {
  min-width: 1200px;
  overflow-x: auto;
}
.table-variation::-webkit-scrollbar {
  height: 7px;
}
.table-variation::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table-variation::-webkit-scrollbar-thumb {
  background: #888;
}
.table-variation::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fs-16 {
  font-size: 16px !important;
}
.table-responsive thead tr th {
  padding: 0.5rem !important;
}
.mw-300px {
  max-width: 300px !important;
}
.variation-price small {
  font-weight: normal !important;
  font-size: 13px;
}
#general-tab .nav-item {
  width: calc(100% / 3);
  text-align: center;
}
.content-center {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}
label, .form-label {
  margin-left: 0px !important;
  color: black;
}
.table-responsive thead tr th {
  padding-left: 0px !important;
}
.table > :not(caption) > * > * {
  padding-left: 0px !important;
}
.text-danger {
  color: red;
}
.is-invalid {
  font-size: 12px;
}
</style>
