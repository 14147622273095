<template>
  <apexchart
    type="bar"
    height="350"
    :options="chartOptions"
    :series="dataChart"
    @dataPointSelection="handleDataPointSelection"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import ColorChart from "../../../config/colorChart.ts";
import wNumb from "wnumb";
import EventBus from "vue3-eventbus";

export default {
  name: "BarChart",
  props: {
    series: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    crossFilter: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      isFilter: false,
      dataChart: [
        {
          data: this.series,
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: this.series.length == 1 ? "10%" : "30%",
            distributed: true,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.labels,
          labels: {
            formatter: function (val) {
              var Format = wNumb({
                prefix: "$ ",
                thousand: ",",
              });

              return Format.to(val);
            },
          },
          axisBorder: {
            show: false,
          },
        },
        colors: ColorChart,
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          strokeDashArray: 4,
        },
        tooltip: {
          y: {
            title: {
              formatter: () => "",
            },
            formatter: function (val) {
              var Format = wNumb({
                prefix: "$ ",
                thousand: ",",
              });

              return Format.to(val);
            },
          },
        },
      },
    };
  },
  methods: {
    handleDataPointSelection(event, chartContext, config) {
      
      if (!this.isFilter) {
        this.isFilter = true;

        if (this.crossFilter) {
          var dataPointIndex = config.dataPointIndex;
          EventBus.emit("ReloadHighestRevenueItemsChart", dataPointIndex);
        }

        setTimeout(() => {
          this.isFilter = false;
        }, 1500);
      }
    },
  },
};
</script>

<style></style>
